import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Backdrop,
	Box,
	Button,
	Chip,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Input,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Switch,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { getDefaultTrackers } from './DefaultTrackers';
import { getJsonData, postJson } from './network';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { TranscribeEngine } from './service_pb';
import BulkRoleChange from './components/BulkRoleChange';
import ImpersonationControls from './components/ImpersonationControls';
import OnBoardingEmailControls from './components/OnBoardingEmailControls';
import BulkInputHandlerForCustomer from './components/BulkInputHandlerForCustomer';
import InfoIcon from '@mui/icons-material/Info';

const disabledTooltipMessages = {
	'Consent Page':
		'Consent Page is disabled because customer has selected outlook calendar',
	'Role based access control': 'Customer cannot be downgraded from rbac.',
};

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'white',
		padding: theme.spacing(3, 2),
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formField: {
		margin: theme.spacing(1),
		flexWrap: 'wrap',
	},
	formSection: {
		width: 400,
	},
	subFormSection: {
		width: 350,
	},
	title: {
		textAlign: 'center',
	},
	Button: {
		padding: '10px',
	},
}));

const userIntegrationFlows = {
	google_calendar_auth_flow: 'Google calendar',
	outlook_calendar_auth_flow: 'Outlook calendar',
	voice_fingerprinting_flow: 'Voice fingerprint',
	wingman_download_flow: 'Wingman',
	nylas_v2_sync_flow: 'Nylas Email',
};

const customerIntegrationFlows = {
	salesforce_sync_flow: 'Salesforce',
	slack_auth_flow: 'Slack',
	hubspot_sync_flow: 'Hubspot',
	freshsales_sync_flow: 'Freshsales',
	freshcaller_sync_flow: 'Freshcaller',
	outreach_sync_flow: 'Outreach',
	leadsquared_sync_flow: 'Leadsquared',
	aircall_sync_flow: 'Aircall',
	hubspot_dialer_flow: 'Hubspot dialer',
	salesloft_sync_flow: 'Salesloft',
	dialpad_sync_flow: 'Dialpad',
	pipedrive_sync_flow: 'Pipedrive',
	frontspin_sync_flow: 'Frontspin',
	zoom_sync_flow: 'Zoom Phone',
	ringcentral_sync_flow: 'Ringcentral',
	close_sync_flow: 'Close',
	align_auth_flow: 'Align',
	highspot_sync_flow: 'Highspot',
	webex_sync_flow: 'Webex',
	teams_auth_flow: 'MS Teams',
};

const allIntegrationFlows = [
	...new Set([
		...Object.keys(userIntegrationFlows),
		...Object.keys(customerIntegrationFlows),
	]),
];

const supportedCrms = [
	'HUBSPOT',
	'PIPEDRIVE',
	'SALESFORCE',
	'FRESHSALES',
	'CLOSE',
	'RESTAPI',
];
const crms = ['NONE', ...supportedCrms];
const dialerTypes = [
	'HUBSPOT',
	'RINGCENTRAL',
	'OUTREACH',
	'AIRCALL_RECORDING',
	'FRESHCALLER',
	'DIALPAD',
	'SALESLOFT',
	'CALL_HIPPO',
	'FRONTSPIN',
	'ZOOM_PHONE',
	'TALKDESK',
];
const diarizationEngines = [
	'DEFAULT_DIARIZATION_ENGINE',
	'KALDI_DIARIZATION_ENGINE',
	'DEEPAFFECTS_DIARIZATION_ENGINE',
	'GOOGLE_DIARIZATION_ENGINE',
	'PYANNOTE_DIARIZATION_ENGINE',
];
const transcribeEngines = Object.keys(TranscribeEngine);
const amazonTranscribeEngine = transcribeEngines.find(
	(ai) => ai.toLowerCase().indexOf('amazon') !== -1
);
const knowlarityTranscribeEngine = transcribeEngines.find(
	(ai) => ai.toLowerCase().indexOf('knowlarity') !== -1
);
const assemblyTranscribeEngine = transcribeEngines.find(
	(ai) => ai.toLowerCase().indexOf('assembly') !== -1
);
const whisperTranscribeEngine = transcribeEngines.find(
	(ai) => ai.toLowerCase().indexOf('whisper') !== -1
);
const defaultTranscribeEngine = transcribeEngines.find(
	(ai) => ai.toLowerCase().indexOf('default') !== -1
);
const defaultLanguage = 'ENGLISH_GLOBAL';
const sentimentAnalyzers = [
	'DEFAULT_SENTIMENT_ANALYZER',
	'STANFORD',
	'NLTK_VADER',
	'GOOGLE',
	'WATSON',
	'WATSON_TONE',
	'VOKATURI',
];
const streamingFormats = ['wav', 'flac'];
const streamingRates = [44100, 16000];

const defaultGameTapes = [
	'Wow Moments! 😮',
	'Competitors 🍴',
	'Pricing & Negotiations 💰',
	'Objection Handling 🤔',
	'Onboarding Tapes 🎓',
	'Customer Stories 📄',
];

export default function Customer({
	isNew
}) {
	const classes = useStyles();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const [id, setId] = useState(null);
	const [name, setName] = useState('');
	const [authorizedDomains, setAuthorizedDomains] = useState([]);
	const [wingmanEnabled, setWingmanEnabled] = useState(true);
	const [msTeamsIntegrationEnabled, setMsTeamsIntegrationEnabled] = useState(false);
	const [orumIntegrationEnabled, setOrumIntegrationEnabled] = useState(false);
	const [desktopAppEnabledForLinux, setDesktopAppEnabledForLinux] =
		useState(false);
	const [userIntegrations, setUserIntegrations] = useState([]);
	const [customerIntegrations, setCustomerIntegrations] = useState(isNew ? ["zoom_sync_flow"] : []);
	const [domain, setDomain] = useState('');
	const [transcriptionHintInput, setTranscriptionHintInput] = useState('');
	const [diarizeBeforeTranscribe, setDiarizeBeforeTranscribe] =
		useState(false);
	const [calendarJoinCallIfOrganizer, setCalendarJoinCallIfOrganizer] =
		useState(false);
	const [
		useDiarizationFromTranscriptionOutput,
		setUseDiarizationFromTranscriptionOutput,
	] = useState(false);
	const [defaultCRM, setDefaultCRM] = useState('NONE');
	const [diarizationEngine, setDiarizationEngine] = useState(
		diarizationEngines[4]
	);
	const [transcribeEngine, setTranscribeEngine] = useState(
		defaultTranscribeEngine
	);
	const [languageCodes, setLanguageCodes] = useState([defaultLanguage]);
	const [transcriptionHints, setTranscriptionHints] = useState([]);
	const [sentimentAnalyzer, setSentimentAnalyzer] = useState(
		sentimentAnalyzers[4]
	);
	const [streamingFormat, setStreamingFormat] = useState(streamingFormats[0]);
	const [streamingRate, setStreamingRate] = useState(streamingRates[1]);
	const [defaultMonologueThreshold, setDefaultMonologueThreshold] =
		useState('60.0'); // From Strings
	const [defaultCurrency, setDefaultCurrency] = useState('$');
	const [numSpeakers, setNumSpeakers] = useState('6'); // From Turon
	const [kaldiDiarizationTargetEnergy, setKaldiDiarizationTargetEnergy] =
		useState('0.899999976158142'); // From Turon
	const [
		kaldiDiarizationClusteringThreshold,
		setKaldiDiarizationClusteringThreshold,
	] = useState('4.30000019073486'); // From Turon
	const [
		kaldiClusterToFingerprintThreshold,
		setKaldiClusterToFingerprintThreshold,
	] = useState('3.44000005722046'); // From Strings
	const [repConfig, setRepConfig] = useState([]);
	const [repConfigChannel, setRepConfigChannel] = useState('0');
	const [repConfigCallType, setRepConfigCallType] = useState('');
	const [repConfigPrefix, setRepConfigPrefix] = useState('');
	const [repConfigError, setRepConfigError] = useState(false);
	const [transcribeModelId, setTranscribeModelId] = useState('video');
	const [beta, setBeta] = useState(false);
	const [postCallsToCrm, setPostCallsToCrm] = useState(true);
	const [pushSummaryToCrm, setPushSummaryToCrm] = useState(true);
	const [isGrooveCustomer, setIsGrooveCustomer] = useState(false);
	const [botName, setBotName] = useState('Clari Copilot');
	const [
		callNotificationMinutesInAdvance,
		setCallNotificationMinutesInAdvance,
	] = useState('10');
	const [videoRecordingEnabled, setVideoRecordingEnabled] = useState(true);
	const [slackAlertsEnabled, setSlackAlertsEnabled] = useState(false);
	const [recordingDisabled, setRecordingDisabled] = useState(false);
	const [wingmanAccessDisabled, setWingmanAccessDisabled] = useState(false);
	const [recorderDiarizationEnabled, setRecorderDiarizationEnabled] =
		useState(true);
	const [cueCardsEnabled, setCueCardsEnabled] = useState(true);
	const [ssoEnabled, setSsoEnabled] = useState(false);
	const [shareCallWhitelabelEnabled, setShareCallWhitelabelEnabled] = useState(false);
	const [automaticDataDeletionEnabled, setAutomaticDataDeletionEnabled] =
		useState(false);
	const [dxpEnabled, setDxpEnabled] = useState(false);
	const [ssoDirectoryEnabled, setSsoDirectoryEnabled] = useState(false);
	const [botRenamingEnabled, setBotRenamingEnabled] = useState(false);
	const [audioConsentEnabled, setAudioConsentEnabled] = useState(false);
	const [consentPageEnabled, setConsentPageEnabled] = useState(true);
	const [consentPageConfig, setConsentPageConfig] = useState({});
	const [crmNotesEnabled, setCrmNotesEnabled] = useState(true);
	const [activityDashboardEnabled, setActivityDashboardEnabled] =
		useState(true);
	const [keywordEmailEnabled, setKeywordEmailEnabled] = useState(true);
	const [enableInternalMeetingRecording, setEnableInternalMeetingRecording] =
		useState(true);
	const [crmUpdatesEnabled, setCrmUpdatesEnabled] = useState(true);
	const [pricingTier, setPricingTier] = useState('tier1');
	const [keyPhrases, setKeyPhrases] = useState(getDefaultTrackers());
	const [callPrivacyEnabled, setCallPrivacyEnabled] = useState(true);
	const [wingmanAPIEnabled, setWingmanAPIEnabled] = useState(false);
	const [gameTapeCategories, setGameTapeCategories] =
		useState(defaultGameTapes);
	const [admins, setAdmins] = useState([]);
	const [updatingRecordingState, setUpdatingRecording] = useState(false);
	const [updatingWingmanAccessState, setUpdatingWingmanAccess] =
		useState(false);
	const [botJoinType, setBotJoinType] = useState('ALL_EXTERNAL_MEETINGS');
	const [enableScorecard, setEnableScorecard] = useState(true);
	const [enableZoomPhoneAudioDownload, setZoomPhoneAudioDownload] =
		useState(false);
	const [enableZoomVideoDownload, setZoomVideoDownload] = useState(false);
	const [enableSalesforceCRMSettings, setEnableSalesforceCRMSettings] =
		useState(false);
	const [experiments, setExperiments] = useState([]);
	const [experimentsInput, setExperimentsInput] = useState('');
	const [trialCustomer, setTrialCustomer] = useState(false);
	const [clariCustomer, setClariCustomer] = useState(false);
	const [ignoreSubscriptionUpdates, setIgnoreSubscriptionUpdates] =
		useState(false);
	const [maxCallLengthMinutes, setMaxCallLengthMinutes] = useState(100);
	const [openModal, setOpenModal] = useState(false);
	const [isRoleChangeModalOpen, setIsRoleChangeModalOpen] = useState(false);
	const [allRolesAvailableForCustomer, setAllRoleAvailableForCustomer] =
		useState([]);
	const [totalRecordingUsersCount, setTotalRecordingUsersCount] = useState(0);
	const [rbacEnabled, setRbacEnabled] = useState(false);
	const [docRbacEnabled, setDocRbacEnabled] = useState(false);
	const [disabledFieldsInTierOption, setDisabledFieldsInTierOption] =
		useState([]);
	const [smartTopicsEnabled, setSmartTopicsEnabled] =
		useState(false);
	const [recommendationsEnabled, setRecommendationsEnabled] =
		useState(false);
	const [newTopicsDashboardEnabled, setNewTopicsDashboard] = useState(false);
	const [
		enableImpersonationRestrictions,
		setEnableImpersonationRestrictions,
	] = useState(false);
	const [
		allowUsersToEnableImpersonation,
		setAllowUsersToEnableImpersonation,
	] = useState(false);
	const [
		listOfAdminsSelectedToImpersonate,
		setListOfAdminsSelectedToImpersonate,
	] = useState([]);
	const [languageCodesList, setLanguageCodesList] = useState({
		[defaultLanguage]: displayStr(defaultLanguage),
	});
	const [allowNormalLogin, setAllowNormalLogin] = useState(false);
	const [doNotSendOnBoardingEmails, setDoNotSendOnBoardingEmails] =
		useState(false);
	const [
		currentCustomerDonotSendOnboardingEmailFlag,
		setCurrentCustomerDonotSendOnboardingEmailFlag,
	] = useState(false);
	const [askRevAIEanbled, setAskRevAIEanbled] = useState(false);
	const [newNavBarEnabled, setNewNavBarEnabled] = useState(false);
	const [multiLanguageSupport, setMultiLanguageSupport] = useState(false);
    const [licenseAuditEnabled, setLicenseAuditEnabled] = useState(false);
	const [licenseInfo, setLicenseInfo] = useState({});
	const [updatedLicenseCount, setUpdatedLicenseCount] = useState(0);
	// Admin led state
	const [isAdminLedUserManagementEnabled, setAdminLedUserManagementEnabled] = useState(false);
	const [isAdminLedBulkUserUploadEnabled, setAdminLedBulkUserUploadEnabled] = useState(false);
	const [
		isAdminLedExternalPlatformNotificationsEnabled,
		setAdminLedExternalPlatformNotificationsEnabled
	] = useState(false);
    const [isAdminLedInAppChecklistEnabled,setAdminLedInAppChecklistEnabled] = useState(false);

    const unifiedAuthStates = {
        replicationSelection: "REPLCIATION_SELECTION",
        waitingReplication: "WAITING_REPLICATION_COOL_OFF",
        loginSelection: "UNIFIED_LOGIN_EXPERIENCE_SELECTION",
        loginDone: "UNIFIED_LOGIN_EXPERIENCE"
    }
    const [unifiedAuth, setUnifiedAuth] = useState(unifiedAuthStates.replicationSelection);
    const [unifiedAuthReplicationTimestamp, setUnifiedAuthReplicationTimestamp] = useState(Date.now());
	const pricingTierOptions = {
		tier2: 0,
		tier1: 1,
		tier0: 2,
	};
	const publicDomains = ['aol.com', 'att.net', 'fastmail.com', 'gmx.com', 'gmail.com', 'hushmail.com', 'icloud.com', 'lavabit.com', 'live.com', 'mail.com', 'mail.ru', 'outlook.com', 'outlook.in', 'protonmail.com', 'rediffmail.com', 'tutanota.com', 'yahoo.com', 'yandex.com', 'ymail.com', 'zoho.com', 'microsoft.com'];

	const { customerId } = useParams();

	const betaFeatures = [
		{
			name: "Custom Smart Topics",
			state: smartTopicsEnabled,
			setter: setSmartTopicsEnabled
		},
		{
			name: "Topics Recommendations",
			state: recommendationsEnabled,
			setter: setRecommendationsEnabled
		},
		{
			name: "New Topic Dashboard",
			state: newTopicsDashboardEnabled,
			setter: setNewTopicsDashboard
		}
	]

	const optionalFeatures = [
		{
			name: 'SSO',
			state: ssoEnabled,
			setter: setSsoEnabled,
		},
		{
			name: 'DXP (Support Login)',
			state: dxpEnabled,
			setter: setDxpEnabled,
		},
		{
			name: 'Role based access control',
			state: rbacEnabled,
			setter: setRbacEnabled,
		},
		{
			name: 'SSO Directory',
			state: ssoDirectoryEnabled,
			setter: setSsoDirectoryEnabled,
		},
		{
			name: 'Cue cards',
			state: cueCardsEnabled,
			setter: setCueCardsEnabled,
		},
		{
			name: 'Crm notes',
			state: crmNotesEnabled,
			setter: setCrmNotesEnabled,
		},
		{
			name: 'Activity dashboard',
			state: activityDashboardEnabled,
			setter: setActivityDashboardEnabled,
		},
		{
			name: 'Call Privacy',
			state: callPrivacyEnabled,
			setter: setCallPrivacyEnabled,
		},
		{
			name: 'Wingman API',
			state: wingmanAPIEnabled,
			setter: setWingmanAPIEnabled,
		},
		{
			name: 'Keyword email',
			state: keywordEmailEnabled,
			setter: setKeywordEmailEnabled,
		},
		{
			name: 'CRM Updates',
			state: crmUpdatesEnabled,
			setter: setCrmUpdatesEnabled,
		},
		{
			name: 'Bot Renaming',
			state: botRenamingEnabled,
			setter: setBotRenamingEnabled,
		},
		{
			name: 'Audio Consent',
			state: audioConsentEnabled,
			setter: setAudioConsentEnabled,
		},
		{
			name: 'Consent Page',
			state: consentPageEnabled,
			setter: setConsentPageEnabled,
		},
		{
			name: 'Internal Meeting Recording',
			state: enableInternalMeetingRecording,
			setter: setEnableInternalMeetingRecording,
		},
		{
			name: 'Scorecard',
			state: enableScorecard,
			setter: setEnableScorecard,
		},
		{
			name: 'Zoom Phone',
			state: enableZoomPhoneAudioDownload,
			setter: setZoomPhoneAudioDownload,
		},
		{
			name: 'Zoom Video Download',
			state: enableZoomVideoDownload,
			setter: setZoomVideoDownload,
		},
		{
			name: 'Salesforce CRM Settings',
			state: enableSalesforceCRMSettings,
			setter: setEnableSalesforceCRMSettings,
		},
		{
			name: 'Allow Normal Login',
			state: allowNormalLogin,
			setter: setAllowNormalLogin,
		},
		{
			name: 'Ask RevAI',
			state: askRevAIEanbled,
			setter: setAskRevAIEanbled,
		},
		{
			name: 'Whitelabel Share Call',
			state: shareCallWhitelabelEnabled,
			setter: setShareCallWhitelabelEnabled,
		},
		{
			name: 'Data Retention',
			state: automaticDataDeletionEnabled,
			setter: setAutomaticDataDeletionEnabled,
		},
		// {
		// 	name: "New NavBar", state: newNavBarEnabled, setter: setNewNavBarEnabled
		// },
	];

	function unpackCustomer(customer) {
		if (!customer.features_gated)
			customer.features_gated = {
				tier: 'tier1',
				cue_cards: true,
				crm_notes: true,
				activity_dashboard: true,
				keyword_email_alerts: true,
				call_privacy: true,
			};
		setId(customer._id);
		setName(customer.name);
		setAllowNormalLogin(customer.features_gated.allow_normal_login);
		setAuthorizedDomains(customer.authorized_domains);
		setDefaultCRM(customer.default_crm);
		setUserIntegrations(unpackIntegrationFlows(customer.integration_flows));
		setCustomerIntegrations(
			unpackIntegrationFlows(customer.customer_level_integration_flows)
		);
		setDefaultMonologueThreshold(customer.default_monologue_threshold);
		setDefaultCurrency(customer.default_currency);
		setCallNotificationMinutesInAdvance(
			customer.call_notification_seconds_in_advance == null
				? ''
				: customer.call_notification_seconds_in_advance / 60
		);
		setWingmanEnabled(customer.wingman_enabled);
		setDesktopAppEnabledForLinux(
			customer.features_gated.desktop_app_enabled_for_linux
		);
		setVideoRecordingEnabled(customer.video_recording_enabled);
		setRecordingDisabled(customer.recording_disabled);
		setWingmanAccessDisabled(customer.access_disabled);
		setRecorderDiarizationEnabled(customer.recorder_diarization_enabled);
		setCueCardsEnabled(customer.features_gated.cue_cards);
		setCrmNotesEnabled(customer.features_gated.crm_notes);
		setActivityDashboardEnabled(customer.features_gated.activity_dashboard);
		setCallPrivacyEnabled(customer.features_gated.call_privacy);
		setWingmanAPIEnabled(customer.features_gated.wingman_api);
		setKeywordEmailEnabled(customer.features_gated.keyword_email_alerts);
		setEnableInternalMeetingRecording(
			customer.features_gated.enable_internal_meeting_recording
		);
		setNewNavBarEnabled(customer.features_gated.new_navbar_enabled);
		setEnableScorecard(customer.features_gated.scorecard);
		setBotRenamingEnabled(customer.features_gated.bot_renaming);
		setAudioConsentEnabled(customer.features_gated.recording_consent_audio);
		setConsentPageEnabled(customer.features_gated.recording_consent_page);
		setConsentPageConfig(customer.consent_page_config);
		setCrmUpdatesEnabled(customer.features_gated.crm_updates);
		setDiarizationEngine(
			customer.process_config.diarization_options.engine
		);
		setNumSpeakers(
			customer.process_config.diarization_options.num_speakers
		);
		setKaldiDiarizationTargetEnergy(
			customer.process_config.diarization_options
				.kaldi_diarization_target_energy
		);
		setKaldiDiarizationClusteringThreshold(
			customer.process_config.diarization_options
				.kaldi_diarization_clustering_threshold
		);
		setRepConfig(
			customer.process_config.diarization_options?.rep_channel_config ||
				[]
		);
		setKaldiClusterToFingerprintThreshold(
			customer.process_config.diarization_options
				.kaldi_cluster_to_fingerprint_threshold
		);
		setTranscribeEngine(customer.process_config.transcribe_options.engine);
		setTranscribeModelId(
			customer.process_config.transcribe_options.transcribe_model_id
		);
		setLanguageCodes(
			customer.process_config.transcribe_options.language_codes
		);
		setTranscriptionHints(
			customer.process_config.transcribe_options.transcription_hints || []
		);
		setDiarizeBeforeTranscribe(
			customer.process_config.diarize_before_transcribe
		);
		setSentimentAnalyzer(customer.process_config.sentimentAnalyzer);
		setStreamingFormat(
			customer.process_config.transcribe_options.streaming_format
		);
		setStreamingRate(
			customer.process_config.transcribe_options.streaming_rate
		);
		setCalendarJoinCallIfOrganizer(
			customer.process_config.calendarJoinCallIfOrganizer
		);
		setUseDiarizationFromTranscriptionOutput(
			customer.process_config.diarization_options
				.diarization_from_transcription_output
		);
		setBeta(customer.process_config.beta);
		setMaxCallLengthMinutes(
			customer.process_config.max_call_length_minutes
		);
		setPostCallsToCrm(customer.post_calls_to_crm);
		setPushSummaryToCrm(
			customer.push_summary_to_crm === undefined
				? true
				: customer.push_summary_to_crm
		);
		setIsGrooveCustomer(
			customer.is_groove_customer ?? false
		);
		setBotName(customer.process_config.bot_name);
		setBotJoinType(customer.process_config.bot_join_type);
		setPricingTier(customer.features_gated.tier);
		setKeyPhrases(customer.key_phrases);
		setGameTapeCategories(customer.gametape_categories || defaultGameTapes);
		setZoomPhoneAudioDownload(
			customer.features_gated.zoom_audio_download_enabled
		);
		setZoomVideoDownload(
			customer.features_gated.zoom_video_download_enabled
		);
		setEnableSalesforceCRMSettings(
			customer.features_gated.salesforce_crm_settings_enabled
		);
		setExperiments(customer.features_gated.experiments || []);
		setSsoEnabled(customer.features_gated.sso_enabled);
		setShareCallWhitelabelEnabled(customer.features_gated.share_call_whitelabel_enabled)
		setAutomaticDataDeletionEnabled(
			customer?.features_gated?.automatic_data_deletion_enabled ?? false
		);
		setDxpEnabled(customer.features_gated.dxp_enabled);
		setSsoDirectoryEnabled(customer.features_gated.sso_directory_enabled);
		setTrialCustomer(customer.trial_customer);
		setClariCustomer(customer.clari_customer);
		setIgnoreSubscriptionUpdates(customer.ignore_subscription_updates);
		setRbacEnabled(customer.features_gated.rbac_enabled);
		setDocRbacEnabled(customer.features_gated.rbac_enabled);
		setMsTeamsIntegrationEnabled(customer.features_gated.ms_teams_enabled && customer.customer_level_integration_flows.teams_auth_flow);
		setOrumIntegrationEnabled(customer.features_gated.orum_enabled);
		setRecommendationsEnabled(customer.features_gated?.topic_recommendations_enabled ?? false);
		setSmartTopicsEnabled(customer.features_gated?.smart_topics_enabled ?? false);
		setNewTopicsDashboard(customer.features_gated?.new_topics_dashboard_enabled ?? false);

		setEnableImpersonationRestrictions(
			customer.enable_impersonation_restrictions
		);
		setAllowUsersToEnableImpersonation(
			customer.allow_users_to_enable_impersonation
		);
		setListOfAdminsSelectedToImpersonate(
			customer.list_of_admins_selected_to_impersonate
		);
		setMultiLanguageSupport(
			customer.features_gated.multi_language_support ?? false
		);
		setDoNotSendOnBoardingEmails(
			customer.do_not_sent_onboarding_emails ?? false
		);
		setCurrentCustomerDonotSendOnboardingEmailFlag(
			customer.do_not_sent_onboarding_emails ?? false
		);
		fetchLanguageCodesList(
			customer.process_config.transcribe_options.language_codes
		);
		setAskRevAIEanbled(customer.features_gated.ask_rev_ai_enabled);

        if(customer?.unified_auth) {
            if(customer?.enable_oidc_unified_auth) {
                setUnifiedAuth(unifiedAuthStates.loginDone);
            } else {
                const currentTimestamp = Date.now();
                const timestamp = customer?.unified_auth_enable_timestamp ?? Date.now();
                if(currentTimestamp > timestamp) {
                    setUnifiedAuth(unifiedAuthStates.loginSelection);
                } else {
                    setUnifiedAuthReplicationTimestamp(timestamp);
                    setUnifiedAuth(unifiedAuthStates.waitingReplication);
                }
            }
        } else {
            setUnifiedAuth(unifiedAuthStates.replicationSelection);
        }
		setAdminLedUserManagementEnabled(customer.features_gated.admin_led?.user_management_enabled);
		setLicenseAuditEnabled(customer?.license_info?.license_management_enabled ?? false);
		setLicenseInfo(customer?.license_info);
		setUpdatedLicenseCount(customer?.license_info?.total_purchased_licenses ?? 0);
		setAdminLedBulkUserUploadEnabled(customer.features_gated.admin_led?.bulk_user_upload_enabled);
		setAdminLedExternalPlatformNotificationsEnabled(
			customer.features_gated.admin_led?.external_platform_notifications_enabled
		);
		setAdminLedInAppChecklistEnabled(
			customer.features_gated.admin_led?.in_app_checklist
		);
	}

	useEffect(() => {
		if (rbacEnabled) {
			setDisabledFieldsInTierOption((prevState) => [
				...prevState,
				'Role based access control',
			]);
		} else {
			setDisabledFieldsInTierOption((prevState) =>
				prevState.filter(
					(disableField) =>
						disableField !== 'Role based access control'
				)
			);
		}
	}, [rbacEnabled]);

	function packCustomer() {
		// !!! IMPORTANT !!!
		// Do not add any field here without unpacking the field from db in unpackCustomer function.
		// It might reset the field in db unintentionally.
		return {
			_id: id,
			name,
			authorized_domains: authorizedDomains,
			default_crm: defaultCRM,
			integration_flows: buildIntegrationFlows(userIntegrations),
			customer_level_integration_flows:
				buildIntegrationFlows(customerIntegrations),
			default_monologue_threshold: Number.parseFloat(
				defaultMonologueThreshold
			),
			default_currency: defaultCurrency,
			call_notification_seconds_in_advance:
				callNotificationMinutesInAdvance === ''
					? null
					: Number.parseInt(callNotificationMinutesInAdvance) * 60,
			wingman_enabled: wingmanEnabled,
			video_recording_enabled: videoRecordingEnabled,
			recording_disabled: recordingDisabled,
			access_disabled: wingmanAccessDisabled,
			recorder_diarization_enabled: recorderDiarizationEnabled,
			key_phrases: keyPhrases,
			post_calls_to_crm: postCallsToCrm,
			push_summary_to_crm: pushSummaryToCrm,
			is_groove_customer: isGrooveCustomer,
			trial_customer: trialCustomer,
			clari_customer: clariCustomer,
			ignore_subscription_updates: ignoreSubscriptionUpdates,
			consent_page_config: consentPageConfig,
			license_info:{...licenseInfo,license_management_enabled:licenseAuditEnabled},
			features_gated: {
				allow_normal_login: allowNormalLogin,
				tier: pricingTier,
				cue_cards: cueCardsEnabled,
				crm_notes: crmNotesEnabled,
				activity_dashboard: activityDashboardEnabled,
				keyword_email_alerts: keywordEmailEnabled,
				enable_internal_meeting_recording:
					enableInternalMeetingRecording,
				scorecard: enableScorecard,
				bot_renaming: botRenamingEnabled,
				recording_consent_page: consentPageEnabled,
				recording_consent_audio: audioConsentEnabled,
				new_navbar_enabled: newNavBarEnabled,
				crm_updates: crmUpdatesEnabled,
				call_privacy: callPrivacyEnabled,
				wingman_api: wingmanAPIEnabled,
				zoom_audio_download_enabled: enableZoomPhoneAudioDownload,
				zoom_video_download_enabled: enableZoomVideoDownload,
				salesforce_crm_settings_enabled: enableSalesforceCRMSettings,
				experiments: experiments,
				dxp_enabled: dxpEnabled,
				sso_enabled: ssoEnabled,
				automatic_data_deletion_enabled: automaticDataDeletionEnabled,
				sso_directory_enabled: ssoDirectoryEnabled,
				rbac_enabled: rbacEnabled,
				multi_language_support: isGrowthPlan()
					? false
					: multiLanguageSupport,
				ask_rev_ai_enabled: askRevAIEanbled,
				desktop_app_enabled_for_linux: desktopAppEnabledForLinux,
				admin_led: {
					user_management_enabled: isAdminLedUserManagementEnabled,
					bulk_user_upload_enabled: isAdminLedBulkUserUploadEnabled,
					external_platform_notifications_enabled: isAdminLedExternalPlatformNotificationsEnabled,
					in_app_checklist: isAdminLedInAppChecklistEnabled,
				},
				share_call_whitelabel_enabled: shareCallWhitelabelEnabled,
				orum_enabled: orumIntegrationEnabled,
				new_topics_dashboard_enabled: newTopicsDashboardEnabled,
				smart_topics_enabled: smartTopicsEnabled,
				topic_recommendations_enabled: recommendationsEnabled
			},
			gametape_categories: gameTapeCategories,
			process_config: {
				diarization_options: {
					engine: diarizationEngine,
					diarization_from_transcription_output:
						useDiarizationFromTranscriptionOutput,
					num_speakers: Number.parseInt(numSpeakers),
					kaldi_diarization_target_energy: Number.parseFloat(
						kaldiDiarizationTargetEnergy
					),
					kaldi_diarization_clustering_threshold: Number.parseFloat(
						kaldiDiarizationClusteringThreshold
					),
					rep_channel_config: repConfig,
					kaldi_cluster_to_fingerprint_threshold: Number.parseFloat(
						kaldiClusterToFingerprintThreshold
					),
				},
				transcribe_options: {
					engine: transcribeEngine,
					transcribe_model_id: transcribeModelId,
					language_codes:
						languageCodes?.length > 0
							? languageCodes
							: [defaultLanguage],
					transcription_hints: transcriptionHints,
					streaming_format: streamingFormat,
				},
				diarize_before_transcribe: diarizeBeforeTranscribe,
				sentimentAnalyzer,
				calendarJoinCallIfOrganizer,
				beta,
				max_call_length_minutes: maxCallLengthMinutes,
				bot_join_type: botJoinType,
				bot_name: botName !== '' ? botName : undefined,
			},
			enable_impersonation_restrictions: enableImpersonationRestrictions,
			allow_users_to_enable_impersonation:
				allowUsersToEnableImpersonation,
			list_of_admins_selected_to_impersonate:
				listOfAdminsSelectedToImpersonate,
			do_not_sent_onboarding_emails: doNotSendOnBoardingEmails,
		};
	}

	function displayStr(item) {
		const str = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
		return str.replace(/_/g, ' ');
	}

	useEffect(() => {
		fetchLanguageCodesList(languageCodes);
		if (customerId) {
			console.log('Fetching customer data', customerId);
			fetchCustomerData(customerId);
		} else {
			console.log('creating new customer');
		}
	}, []);

	async function fetchLanguageCodesList(languageCodes) {
		const languageCodesListLocal = await getJsonData(
			'/customers/get_all_supported_transcription_languages'
		);
		const missingLanguageCodes = {};
		languageCodes.forEach((key) => {
			if (!languageCodesListLocal.hasOwnProperty(key))
				missingLanguageCodes[key] = displayStr(key);
		});
		setLanguageCodesList({
			...languageCodesListLocal,
			...missingLanguageCodes,
		});
	}

	async function fetchCustomerData(customerId) {
		setLoading(true);
		var customer = await getJsonData('/customers/get/' + customerId);
		var allRoles = await getJsonData(
			'/customers/get_all_roles/' + customerId
		);
		
		const totalRecordingUsersCount = allRoles
        .filter(role => role?.recordingAccess)
        .reduce((sum, role) => sum + (role?.usersCount || 0), 0);
		setTotalRecordingUsersCount(totalRecordingUsersCount);
		setAllRoleAvailableForCustomer(allRoles);
		unpackCustomer(customer);
		setLoading(false);
		setAdmins(await getJsonData('/customers/admins/' + customerId));
	}

	function select(state, setState, arr, multiple) {
		return (
			<Select
				value={state}
				multiple={multiple}
				onChange={(e) => setState(e.target.value)}
			>
				{arr.map((item, i) => (
					<MenuItem key={i} value={item}>
						{displayStr(item)}
					</MenuItem>
				))}
			</Select>
		);
	}

	function selectInteger(state, setState, arr, multiple) {
		return (
			<Select
				value={state}
				multiple={multiple}
				onChange={(e) => setState(e.target.value)}
			>
				{arr.map((item, i) => (
					<MenuItem key={i} value={item}>
						{item}
					</MenuItem>
				))}
			</Select>
		);
	}

	function formElement(elem, label, opacity = 1) {
		return (
			<FormControl
				variant="standard"
				style={{ opacity, marginBottom: '1rem' }}
			>
				{label && <InputLabel>{label}</InputLabel>}
				{elem}
			</FormControl>
		);
	}

	function toggleSwitch(state, setState, label) {
		return (
			<FormControlLabel
				label={label}
				control={
					<Switch
						checked={state}
						color="primary"
						onChange={(e) => setState(e.target.checked)}
					/>
				}
			/>
		);
	}

	function textArea(state, setState, label, type = 'text') {
		return (
			<TextField
				type={type}
				label={label}
				value={state}
				onChange={(e) => setState(e.target.value)}
				style={{ marginBottom: '1rem' }}
			/>
		);
	}

	function integrationField(state, setState, integrations) {
		return (
			<Select
				multiple
				input={<Input />}
				onChange={(e) => setState(e.target.value)}
				value={state}
				renderValue={(selected) => (
					<div style={{ maxWidth: '390px' }}>
						{selected.map((value) => (
							<Chip key={value} label={integrations[value]} />
						))}
					</div>
				)}
				MenuProps={{ style: { maxHeight: '400px' } }}
			>
				{Object.keys(integrations).map((key) => (
					<MenuItem key={key} value={key}>
						{integrations[key]}
					</MenuItem>
				))}
			</Select>
		);
	}

  async function deleteDomain(domain) {
    const indexToRemove = authorizedDomains.indexOf(domain);
    if (indexToRemove === -1) return;
    const usersAssociatedWithDomain = await getUsersWithAssociatedDomain(customerId, domain);
    if (usersAssociatedWithDomain?.length > 0) {
      const input = prompt(`There are still users in this account associated with the domain ${domain} - This may cause inconsistency in the copilot experience. Type "yes" to proceed? `);
      if (!input || input.toLowerCase() !== "yes") {
        alert("Wrong input, cancelling domain removal");
        return;
      }
    }
		alert("Click Submit to apply the changes");
		const updatedAuthorizedDomains = authorizedDomains.filter((_, index) => index !== indexToRemove);
		setAuthorizedDomains(updatedAuthorizedDomains);
  }

	function deleteExperiment(exp) {
		const index = experiments.indexOf(exp);
		if (index === -1) return;
		experiments.splice(index, 1);
		return [...experiments];
	}

	async function addDomain(e) {
		const val = e.target.value;
		if (e.key !== 'Enter') return;
		if (val === '') return;
		if (checkIfItsAPublicDomain(val)) {
				setDomain("");
				alert(`Please dont add public domains like ${val}`);
				return;
		}
		const existingCustomersWithDomain = await getCustomersWithDomain(val);
		if(existingCustomersWithDomain && existingCustomersWithDomain.length > 0) {
			alert(`Domain ${val} already exists for customers :\n${existingCustomersWithDomain.map(c => c.name).join(",\n")}`);
			setDomain("");
			return;
		}
		setAuthorizedDomains(authorizedDomains.concat(val));
		setDomain('');
    }

  async function getUsersWithAssociatedDomain(customerId, domain) {
    const users = await getAllUsersForCustomer(customerId);
    return users.filter(user => user?.email_id.includes(domain));
  }

  async function getAllUsersForCustomer(customerId) {
    return getJsonData("/customers/users?customerId=" + customerId);
  }

	async function getCustomersWithDomain(domain){
		return getJsonData("/customers/authorized-domains?domain=" + domain);
	}

	function checkIfItsAPublicDomain(newDomain) {
		return publicDomains.filter(domain => domain.toLowerCase() === newDomain.toLowerCase()).length;
	}


	function addRepConfig() {
		let configObj = {
			call_type: repConfigCallType,
			prefix: repConfigPrefix,
			channel: Number.parseInt(repConfigChannel),
		};
		if (repConfigCallType.length === 0) {
			setRepConfigError(true);
			return;
		}
		for (let i = 0; i < repConfig.length; i++) {
			if (repConfig[i]['call_type'] == configObj['call_type']) {
				//It means that there is a duplicate call_type
				setRepConfigError(true);
				return;
			}
		}
		setRepConfig(repConfig.concat(configObj));
		setRepConfigError(false);
	}

	function deleteRepConfig(config) {
		const repConfigLocal = [...repConfig];
		return repConfigLocal.filter(
			(localConfig) => localConfig['call_type'] !== config['call_type']
		);
	}

	function deleteTranscriptionHint(hint) {
		const transcriptionHintsLocal = [...transcriptionHints];
		const index = transcriptionHintsLocal.indexOf(hint);
		if (index === -1) return;
		transcriptionHintsLocal.splice(index, 1);
		return [...transcriptionHintsLocal];
	}

	function addTranscriptionHint(e) {
		const value = e.target.value;
		if (e.key !== 'Enter') return;
		if (value === '') return;
		setTranscriptionHints((transcriptionHints) =>
			transcriptionHints.concat(value)
		);
		setTranscriptionHintInput('');
	}

	function updateDomain(e) {
		setDomain(e.target.value);
	}

	function updateTranscriptionHint(e) {
		setTranscriptionHintInput(e.target.value);
	}

	function buildIntegrationFlows(arr) {
		return allIntegrationFlows.reduce((ret, flow) => {
			ret[flow] = arr.indexOf(flow) !== -1;
			return ret;
		}, {});
	}

	function unpackIntegrationFlows(integrationFlows) {
		return Object.keys(integrationFlows || {}).filter(
			(k) => integrationFlows[k]
		);
	}

	function validateAuthorizedDomains() {
		if (authorizedDomains.length === 0) {
			if (domain) {
				setAuthorizedDomains(authorizedDomains.concat(domain));
				setDomain('');
			}
			return 'Add authorized domains\n';
		}
		return '';
	}

	function validateLicenseInfo(){
		if(updatedLicenseCount < 0){
         return 'License count cannot be negative\n'
		}
		if(totalRecordingUsersCount > updatedLicenseCount){
		//enable this once we do reconciliation and make sure no customer have lesser licenses than recording users
		//	return 'Recording users are more than license count, pls reconcilitae and come back\n' 
		}
		return "";
	}

	function validateDefaultCRM() {
		const crmsSelected = [
			...customerIntegrations,
			...userIntegrations,
		].filter((value) => supportedCrms.includes(value));
		if (
			defaultCRM === null ||
			(defaultCRM === 'NONE' && crmsSelected.length > 0)
		) {
			return 'Select default CRM\n';
		}
		return '';
	}

	function valid() {
		var errors = '';
		errors += validateAuthorizedDomains();
		errors += validateDefaultCRM();
		errors += validateLicenseInfo(); 
		return errors;
	}

	const updateCustomerData = async () => {
		const endpoint = '/customers/' + (id == null ? 'new' : 'update');
		return await postJson(endpoint, { customer: packCustomer() });
	};

	const updateLicenseInfoForCustomer = async() => {
		const endPoint = '/customers/update-licenses'
		const currentPurchasedLicenseCount = licenseInfo?.total_purchased_licenses;
		return await postJson(endPoint ,{licenseCountDelta : updatedLicenseCount - currentPurchasedLicenseCount, customerId:id})
	}

	async function submit() {
		const errors = valid();
		if (errors) {
			alert('Fix these errors before submitting:\n' + errors);
			return;
		}
		try {
			const currentPurchasedLicenseCount = licenseInfo?.total_purchased_licenses;
			if (currentPurchasedLicenseCount !== updatedLicenseCount) {
				const licenseUpdateResponse = await updateLicenseInfoForCustomer();
				if (!licenseUpdateResponse.ok) {
					const errorMessage = await licenseUpdateResponse.text();
					throw new Error(errorMessage || 'Failed to update license info.');
				}
			}
		} catch (error) {
			// Handle errors from updateLicenseInfoForCustomer or other thrown errors
			alert(`Error: ${error.message || error}`);
		}

		updateCustomerData()
				.then(async (resp) => {
					alert(await resp.text());
					if (resp.ok) navigate('/customers/', { replace: true });
				})
				.catch((err) => alert(err));
	}

	function selectableChip(label, state, setState) {
		return (
			<Tooltip
				title={disabledTooltipMessages?.[label] || ''}
				disableHoverListener={
					!disabledFieldsInTierOption.includes(label)
				}
			>
				<span>
					<Chip
						label={label}
						key={label}
						color={state ? 'primary' : 'default'}
						disabled={disabledFieldsInTierOption.includes(label)}
						onClick={() => setState(!state)}
						style={{ margin: '0.25rem' }}
					/>
				</span>
			</Tooltip>
		);
	}

	function isGrowthPlan() {
		return pricingTier === 'tier2';
	}

	function betaFeaturesElements() {
		return betaFeatures.map((f) =>
			selectableChip(f.name, f.state, f.setter)
		);
	}

	function optionalFeaturesElements() {
		const featureChips = optionalFeatures.map((f) =>
			selectableChip(f.name, f.state, f.setter)
		);
		if (!isGrowthPlan())
			featureChips.push(
				selectableChip(
					'Multi Language Support',
					multiLanguageSupport,
					setMultiLanguageSupport
				)
			);
		return featureChips;
	}

	function onTierChange(e, v) {
		const acceleratorTierEnabled = v === 1;
		const enterpriseTierEnabled = v === 2;

		handleEnterpriseTierChange(enterpriseTierEnabled);
		enterpriseTierEnabled && setPricingTier('tier0');

		if (acceleratorTierEnabled) {
			handleAcceleratorTierChange(acceleratorTierEnabled);
			setPricingTier('tier1');
		} else if (!enterpriseTierEnabled) {
			setPricingTier('tier2');
		}
	}

	function isNewCustomer() {
		return id == null;
	}

	function handleAcceleratorTierChange(enabled) {
		setCueCardsEnabled(enabled);
		setCrmNotesEnabled(enabled);
		setActivityDashboardEnabled(enabled);
		setKeywordEmailEnabled(enabled);
		setCrmUpdatesEnabled(enabled);
		setCallPrivacyEnabled(enabled);
		setEnableSalesforceCRMSettings(enabled);
		setConsentPageEnabled(enabled);
		if(!isNewCustomer()) {
			if(docRbacEnabled) {
				setRbacEnabled(docRbacEnabled)
			}else {
				setRbacEnabled(false);
			}
		} else {
			setRbacEnabled(false);
		}

		// When we create new customer
		if (isNewCustomer()) {
			if (enabled) {
				setCallNotificationMinutesInAdvance('10');
				setBotJoinType('EXTERNAL_MEETINGS_HOSTED_BY_RECORDING_USER');
			} else {
				setBotJoinType('ALL_EXTERNAL_MEETINGS');
				setCallNotificationMinutesInAdvance('');
			}
		}
	}

	function handleEnterpriseTierChange(enabled) {
		handleAcceleratorTierChange(enabled);
		setSsoDirectoryEnabled(enabled);
		setSsoEnabled(enabled);
		setWingmanAPIEnabled(enabled);
		if(!isNewCustomer()) {
			if(docRbacEnabled) {
				setRbacEnabled(docRbacEnabled)
			}else {
				setRbacEnabled(enabled);
			}
		} else {
			setRbacEnabled(enabled);
		}
		setShareCallWhitelabelEnabled(enabled);

	}

	function updateRecording(value) {
		setUpdatingRecording(true);
		getJsonData(
			'/billing/' +
				id +
				'/enable_wingman_access?action=recording&value=' +
				value
		)
			.then((res) => {
				console.log('updated recording', res);
			})
			.catch(console.error);
		setUpdatingRecording(false);
		setRecordingDisabled(!value);
	}

	function updateWingmanAccess(value) {
		setUpdatingWingmanAccess(true);
		getJsonData(
			'/billing/' +
				id +
				'/enable_wingman_access?action=access&value=' +
				value
		)
			.then((res) => {
				console.log('updated wingman access', res);
			})
			.catch(console.error);
		setUpdatingWingmanAccess(false);
		setUpdatingRecording(false);
		setRecordingDisabled(!value);
		setWingmanAccessDisabled(!value);
	}

	async function deleteSSO() {
		try {
			await postJson('/customers/delete-sso/', { customerId });
			setSsoEnabled(false);
			setSsoDirectoryEnabled(false);
			alert('Deleted SSO Connection\nSubmit to save changes');
		} catch (err) {
			alert(err);
		}
	}

	function disableRecording() {
		const inpName = prompt('Enter name of customer to confirm disabling');
		if (inpName && inpName.toLowerCase() === name.toLowerCase()) {
			updateRecording(false);
			return;
		}
		alert(
			'You were trying to disable recording for "' +
				name +
				'" but you entered "' +
				inpName +
				'", so ignoring request'
		);
	}

	function disableWingmanAccess() {
		const inpName = prompt(
			'Enter name of customer to confirm disabling account'
		);
		if (inpName && inpName.toLowerCase() === name.toLowerCase()) {
			updateWingmanAccess(false);
			return;
		}
		alert(
			'You were trying to disable Wingman Access for "' +
				name +
				'" but you entered "' +
				inpName +
				'", so ignoring request'
		);
	}

	async function disableSSO() {
		const inpName = prompt('Enter name of customer to confirm disabling');
		if (inpName && inpName.toLowerCase() === name.toLowerCase()) {
			await deleteSSO();
			console.log('SSO disabled');
			return;
		}
		alert(
			'You were trying to delete single sign on for "' +
				name +
				'" but you entered "' +
				inpName +
				'", so ignoring request'
		);
	}

	function enableRecording() {
		updateRecording(true);
	}

	function getRecordingStateButton() {
		if (updatingRecordingState) return <CircularProgress />;
		if (recordingDisabled)
			return (
				<Button onClick={enableRecording} startIcon={<ErrorIcon />}>
					Enable recording
				</Button>
			);
		return (
			<Button
				variant="contained"
				color="secondary"
				onClick={disableRecording}
				style={{ width: '100%' }}
				startIcon={<WarningIcon />}
			>
				Disable recording
			</Button>
		);
	}

	function getWingmanAccessStateButton() {
		if (updatingWingmanAccessState) return <CircularProgress />;
		if (wingmanAccessDisabled)
			return (
				<Button
					onClick={() => updateWingmanAccess(true)}
					startIcon={<ErrorIcon />}
				>
					Enable Wingman access
				</Button>
			);
		return (
			<Button
				variant="contained"
				color="secondary"
				onClick={disableWingmanAccess}
				style={{ width: '100%' }}
				startIcon={<WarningIcon />}
			>
				Disable Wingman access
			</Button>
		);
	}


    function getHourMinuteDifference(timestamp1, timestamp2) {
        const difference = Math.abs(timestamp2 - timestamp1);
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        return { hours, minutes };
    }


    async function enableUnifiedAuth() {
		try {
            let newUnifiedAuthState;
            let unifiedAuthStateValue = false;
            let promptMessage;
            switch(unifiedAuth) {
                case unifiedAuthStates.waitingReplication: {
                    const { hours, minutes } = getHourMinuteDifference(Date.now(), unifiedAuthReplicationTimestamp);
                    alert(`Please try again after ${hours} hours & ${minutes} minutes`);
                    return;
                }

                case unifiedAuthStates.loginSelection: {
                    promptMessage = 'Enter name of customer to enable unified login (Login via Clari)';
                    newUnifiedAuthState = unifiedAuthStates.loginDone;
                    unifiedAuthStateValue = true;
                    break;
                }

                case unifiedAuthStates.loginDone: {
                    promptMessage = 'Enter name of customer to disable unified login (Login via Clari)';
                    newUnifiedAuthState = unifiedAuthStates.loginSelection;
                    unifiedAuthStateValue = false;
                    break;
                }

                default: {
                    promptMessage = 'Enter name of customer to enable user replication';
                    newUnifiedAuthState = unifiedAuthStates.waitingReplication;
                    unifiedAuthStateValue = true;
                }

            }
			const inpName = prompt(promptMessage);
			if (inpName && inpName.toLowerCase() === name.toLowerCase()) {
                setLoading(true);
				await postJson('/customers/unified-authentication', {
					state: [unifiedAuthStates.loginDone, unifiedAuthStates.loginSelection].includes(unifiedAuth) ? "UNIFIED_LOGIN" : "REPLICATION",
                    value: unifiedAuthStateValue,
                    customerId: customerId,
				});
				alert('Action Successful!');
				setUnifiedAuth(newUnifiedAuthState);
                setUnifiedAuthReplicationTimestamp(Date.now() + (3 * 60 * 60 * 1000)); //since we don't close the page, therefore just bumping up the timestmap. Once the page reloads, the correct timestamp will follow.
                setLoading(false);
				return;
			}
			alert(
				'Trying to change the settings for "' +
					name +
					'" but you entered "' +
					inpName +
					'", so ignoring request'
			);
		} catch (err) {
			alert(err);
		}
	}

	async function updateMsteamsIntegrationEnabled() {
		try {
			await postJson(`/customers/integrations/ms_teams/${customerId}/status`, {
				enabled: !msTeamsIntegrationEnabled,
			});
			setMsTeamsIntegrationEnabled(!msTeamsIntegrationEnabled);
		} catch (err) {
			alert(err);
		}
	}

	async function updateOrumIntegrationEnabled() {
		setOrumIntegrationEnabled(!orumIntegrationEnabled);
	}

	async function enableSlackAlerts() {
		try {
			const inpName = prompt(
				'Enter name of customer to enable slack alert for all users of the customer'
			);
			if (inpName && inpName.toLowerCase() === name.toLowerCase()) {
				await postJson('/customers/enable_slack_alerts', {
					customerId,
				});
				alert('Slack Alerts Ebabled');
				setSlackAlertsEnabled(true);
				return;
			}
			alert(
				'You were trying to enable slack alerts for "' +
					name +
					'" but you entered "' +
					inpName +
					'", so ignoring request'
			);
		} catch (err) {
			alert(err);
		}
	}
	function handleGlobalTranscriptionLanguageChange(event) {
		let val = event.target.value;
		if (Array.isArray(val) && val?.length > 1) {
			val = val.slice(-1);
		}
		setLanguageCodes(val);
	}

	function handleGlobalTranscriptionLanguageIsNotEmpty() {
		if (!languageCodes?.length) setLanguageCodes([defaultLanguage]);
	}


    function getUnifiedAuthButtonMessage(unifiedAuthCurrState) {
        switch (unifiedAuthCurrState) {
            case unifiedAuthStates.replicationSelection:
                return "ENABLE USER REPLICATION";

            case unifiedAuthStates.waitingReplication:
                return "ENABLE UNIFIED LOGIN EXPERIENCE";

            case unifiedAuthStates.loginSelection:
                return "ENABLE UNIFIED LOGIN EXPERIENCE";

            case unifiedAuthStates.loginDone:
                return "DISABLE UNIFIED LOGIN EXPERIENCE";

            default:
                return "RELOAD!"
        }
    }

	function handleLicenseUpdate(delta){
		const currentPurchasedLicenseCount = licenseInfo?.total_purchased_licenses;
		if(parseInt(delta)){
		 setUpdatedLicenseCount(currentPurchasedLicenseCount+parseInt(delta));
		}else{
		 setUpdatedLicenseCount(currentPurchasedLicenseCount)
		}
	}

	return (
		<>
			<Paper className={classes.root} style={{ overflowY: 'auto' }}>
				<Backdrop open={loading} style={{ zIndex: '5000' }}>
					<CircularProgress />
				</Backdrop>
				<Link to="/customers/">
					<Button variant="contained" color="primary">
						Back
					</Button>
				</Link>
				<FormGroup style={{ width: '500px', margin: 'auto' }}>
					{textArea(name, setName, 'Name')}
					<div >
                        <div style={{display:"flex", marginRight:"20px"}}>
                        <h4>Authorized domains</h4>
                            <Tooltip
                        title={<Typography sx={{ fontSize: "16px", }}>Note: If the organization supports self-signup to Copilot, anyone with a valid email address with the same domain can signup and get added to the Copilot instance.
                        Exercise caution before adding multiple domains and communicate this with the customer.
                        </Typography> }
                        placement="right"
                        >
                        <InfoIcon style={{marginTop:"18px", marginLeft:"8px"}}/>
                        </Tooltip>
                        </div>
                        <Typography>Add only valid business domains like clari.com,adobe.com. Avoid public domains such as gmail.com, outlook.com</Typography>
                    </div>
					<FormControl variant="standard">
						<InputLabel>Authorized domains</InputLabel>
						<Input
							value={domain}
							onChange={updateDomain}
							onKeyDown={addDomain}
						/>
						<div>
							{authorizedDomains.map((domain) => (
								<Chip
									key={domain}
									label={domain}
									onDelete={() => deleteDomain(domain)}
								/>
							))}
						</div>
					</FormControl>
					{formElement(
						integrationField(
							userIntegrations,
							setUserIntegrations,
							userIntegrationFlows
						),
						'User level integrations'
					)}
					{formElement(
						integrationField(
							customerIntegrations,
							setCustomerIntegrations,
							customerIntegrationFlows
						),
						'Customer level integrations'
					)}
					{formElement(
						select(defaultCRM, setDefaultCRM, crms),
						'Default CRM'
					)}
					<FormControl
						variant="standard"
						style={{ marginBottom: '1rem' }}
					>
						<InputLabel>Experiments</InputLabel>
						<Input
							value={experimentsInput}
							onChange={(e) =>
								setExperimentsInput(e.target.value)
							}
							onKeyDown={(e) => {
								if (e.key !== 'Enter') return;
								if (e.target.value === '') return;
								setExperiments(
									experiments.concat(e.target.value)
								);
								setExperimentsInput('');
							}}
						/>
						<div>
							{experiments.map((exp) => (
								<Chip
									key={exp}
									label={exp}
									onDelete={() =>
										setExperiments(deleteExperiment(exp))
									}
								/>
							))}
						</div>
					</FormControl>
					{formElement(
						toggleSwitch(
							wingmanEnabled,
							setWingmanEnabled,
							'Desktop app enabled'
						)
					)}
					{formElement(
						toggleSwitch(
							msTeamsIntegrationEnabled,
							updateMsteamsIntegrationEnabled,
							'MS teams Integration Enabled'
						)
					)}
					{formElement(
						toggleSwitch(
							orumIntegrationEnabled,
							updateOrumIntegrationEnabled,
							'Orum Integration Enabled'
						)
					)}
					{formElement(
						toggleSwitch(
							desktopAppEnabledForLinux,
							setDesktopAppEnabledForLinux,
							'Desktop app enabled for linux'
						)
					)}
					{formElement(toggleSwitch(beta, setBeta, 'Beta'))}
					{formElement(
						toggleSwitch(
							postCallsToCrm,
							setPostCallsToCrm,
							'Post calls to crm'
						),
						null,
						postCallsToCrm === undefined ? 0.5 : 1
					)}
					{formElement(
						toggleSwitch(
							pushSummaryToCrm,
							setPushSummaryToCrm,
							'Push summaries to CRM'
						)
					)}
					{formElement(
						toggleSwitch(
							isGrooveCustomer,
							setIsGrooveCustomer,
							'Is Groove Customer'
						)
					)}

                   {false && 
					<div>

						<Divider/>
						<h3>Enable license audit</h3>
						<h4>{`Totoal recording users present in this org - ${totalRecordingUsersCount} users`}</h4>
						<h4>{`Totoal licenses purchased - ${licenseInfo?.total_purchased_licenses} licenses`}</h4>
						<h4>{`Updated licenses count - ${updatedLicenseCount} licenses`}</h4>
						<h4 style={{ color: 'red' }}>{`If already requested more licenses, pls wait for 30 mins to reflect`}</h4>
						{formElement(
							toggleSwitch(
								licenseAuditEnabled,
								setLicenseAuditEnabled,
								'License Audit'
							)
						)}
						<div style={{marginTop: '-1.25rem'}}>
						{licenseAuditEnabled && textArea(null, handleLicenseUpdate, 'Add/Remove licenses')}
						</div>

					</div>
					}

					<div>
						<Divider />
						<h3>Admin Led Adoption</h3>
						{formElement(
							toggleSwitch(
								isAdminLedUserManagementEnabled,
								setAdminLedUserManagementEnabled,
								'User management'
							)
						)}
						{formElement(
							toggleSwitch(
								isAdminLedBulkUserUploadEnabled,
								setAdminLedBulkUserUploadEnabled,
								'Bulk user upload'
							)
						)}
						{formElement(
							toggleSwitch(
								isAdminLedExternalPlatformNotificationsEnabled,
								setAdminLedExternalPlatformNotificationsEnabled,
								'Customer level Notifications'
							)
						)}
							{formElement(
							toggleSwitch(
								isAdminLedInAppChecklistEnabled,
								setAdminLedInAppChecklistEnabled,
								'In App Checklist'
							)
						)}
						<Divider />
					</div>

					<div>
						<Divider />
						<h3>Billing</h3>
						{formElement(
							toggleSwitch(
								trialCustomer,
								setTrialCustomer,
								'Trial customer'
							)
						)}
						{formElement(
							toggleSwitch(
								clariCustomer,
								setClariCustomer,
								'Managed by clari'
							)
						)}
						{formElement(
							toggleSwitch(
								ignoreSubscriptionUpdates,
								setIgnoreSubscriptionUpdates,
								'Ignore subscription updates'
							)
						)}
						<Divider />
					</div>
					<div>
						<ImpersonationControls
							customerId={customerId}
							enableImpersonationRestrictions={
								enableImpersonationRestrictions
							}
							allowUsersToEnableImpersonation={
								allowUsersToEnableImpersonation
							}
							listOfAdminsSelectedToImpersonate={
								listOfAdminsSelectedToImpersonate
							}
							setEnableImpersonationRestrictions={
								setEnableImpersonationRestrictions
							}
							setAllowUsersToEnableImpersonation={
								setAllowUsersToEnableImpersonation
							}
							setListOfAdminsSelectedToImpersonate={
								setListOfAdminsSelectedToImpersonate
							}
						/>
					</div>
					<div>
						<OnBoardingEmailControls
							customerId={customerId}
							doNotSendOnBoardingEmails={
								doNotSendOnBoardingEmails
							}
							setDoNotSendOnBoardingEmails={
								setDoNotSendOnBoardingEmails
							}
							currentCustomerDonotSendOnboardingEmailFlag={
								currentCustomerDonotSendOnboardingEmailFlag
							}
							classes={classes}
						/>
					</div>
					<br style={{ marginBottom: '2rem' }} />
					<Divider />
					<br style={{ marginBottom: '2rem' }} />
					{textArea(
						callNotificationMinutesInAdvance,
						setCallNotificationMinutesInAdvance,
						'Call notification (minutes in advance)',
						'number'
					)}
					{textArea(
						defaultMonologueThreshold,
						setDefaultMonologueThreshold,
						'Monologue threshold (in seconds)',
						'number'
					)}
					{textArea(
						defaultCurrency,
						setDefaultCurrency,
						'Currency ($)'
					)}
					<a
						href="https://moneyconvert.net/pages/currency-list"
						style={{
							fontSize: '12px',
							marginTop: '-0.75rem',
							marginBottom: '1rem',
							textAlign: 'right',
							color: '#3f51b5',
						}}
						target="_blank"
						rel="noreferrer"
					>
						Currency List
					</a>
					<FormControl variant="standard">
						<InputLabel shrink={true}>Pricing tier</InputLabel>
						<div style={{ marginTop: '15px' }}>
							<Tabs
								value={pricingTierOptions[pricingTier]}
								onChange={onTierChange}
							>
								<Tab label="Growth Plan" />
								<Tab label="Accelerator Plan" />
								<Tab label="Enterprise Plan" />
							</Tabs>
						</div>
					</FormControl>
					<div
						style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
					>
						{optionalFeaturesElements()}
					</div>
					<Divider style={{ backgroundColor: '#000' }} />
					<FormControl variant="outlined" fullWidth margin="normal">
						<h4 style={{margin: "0px"}} >BETA Features</h4>
						<h5 style={{margin: "0px"}} >Do NOT turn on automatically for new customers</h5>
						<div
							style={{ marginTop: '1rem', marginBottom: '1rem' }}
						>
							<Box display="flex" flexWrap="wrap">
								{betaFeaturesElements()}
							</Box>
						</div>
					</FormControl>
					<Divider style={{ backgroundColor: '#000' }} />
					{!isGrowthPlan() && multiLanguageSupport && (
						<div
							style={{
								marginLeft: '0.5rem',
								marginTop: '0.5rem',
								fontSize: '0.75rem',
								color: 'red',
							}}
						>
							Multi Language Support is enabled. Please do not
							make any changes to the below configurations
						</div>
					)}
					<Accordion
						className={
							classes.formField + ' ' + classes.formSection
						}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography>Process config</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{formElement(
									select(
										diarizationEngine,
										setDiarizationEngine,
										diarizationEngines
									),
									'Diarization engine'
								)}
								{formElement(
									toggleSwitch(
										useDiarizationFromTranscriptionOutput,
										setUseDiarizationFromTranscriptionOutput,
										'Use diarization from transcription output'
									)
								)}
								{textArea(
									numSpeakers,
									setNumSpeakers,
									'Num speakers',
									'number'
								)}
								{textArea(
									kaldiDiarizationTargetEnergy,
									setKaldiDiarizationTargetEnergy,
									'Kaldi diarization target energy'
								)}
								{textArea(
									kaldiDiarizationClusteringThreshold,
									setKaldiDiarizationClusteringThreshold,
									'Kaldi diarization clustering threshold'
								)}
								{textArea(
									kaldiClusterToFingerprintThreshold,
									setKaldiClusterToFingerprintThreshold,
									'Kaldi cluster to fingerprint threshold'
								)}
								<Accordion
									className={
										classes.formField +
										' ' +
										classes.subFormSection
									}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
									>
										<Typography>
											Rep channel config
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<FormGroup>
											{textArea(
												repConfigChannel,
												setRepConfigChannel,
												'Rep channel in multi channel audio',
												'number'
											)}
											{formElement(
												select(
													repConfigCallType,
													setRepConfigCallType,
													dialerTypes
												),
												'Call type'
											)}
											<div style={{ color: 'red' }}>
												{repConfigError
													? 'Call type is a required field and should be unique'
													: ''}
											</div>
											{textArea(
												repConfigPrefix,
												setRepConfigPrefix,
												'Optional prefix for call links'
											)}
											<Button
												className={classes.formField}
												variant="contained"
												color={'info'}
												onClick={addRepConfig}
											>
												{'Add rep Config'}
											</Button>
											<div>
												{repConfig.map((config) => (
													<Chip
														key={
															config['call_type']
														}
														label={
															config['call_type']
														}
														onDelete={() =>
															setRepConfig(
																deleteRepConfig(
																	config
																)
															)
														}
													/>
												))}
											</div>
										</FormGroup>
									</AccordionDetails>
								</Accordion>
								{textArea(
									transcribeModelId,
									setTranscribeModelId,
									'Transcribe model id'
								)}
								{formElement(
									select(
										streamingFormat,
										setStreamingFormat,
										streamingFormats
									),
									'Streaming format'
								)}
								{formElement(
									selectInteger(
										streamingRate,
										setStreamingRate,
										streamingRates
									),
									'Streaming rate'
								)}
								{formElement(
									<Select
										value={languageCodes}
										onChange={
											handleGlobalTranscriptionLanguageChange
										}
										onClose={
											handleGlobalTranscriptionLanguageIsNotEmpty
										}
										multiple
									>
										{Object.keys(languageCodesList).map(
											(key) => (
												<MenuItem value={key}>
													{displayStr(key)}
												</MenuItem>
											)
										)}
									</Select>,
									'Language codes'
								)}
								{formElement(
									toggleSwitch(
										diarizeBeforeTranscribe,
										setDiarizeBeforeTranscribe,
										'Diarize before transcribe'
									)
								)}
								{formElement(
									select(
										sentimentAnalyzer,
										setSentimentAnalyzer,
										sentimentAnalyzers
									),
									'Sentiment analyzer'
								)}
								{formElement(
									toggleSwitch(
										calendarJoinCallIfOrganizer,
										setCalendarJoinCallIfOrganizer,
										'Calendar join call if organizer'
									)
								)}
								{textArea(botName, setBotName, 'Bot name')}
								{textArea(
									maxCallLengthMinutes,
									setMaxCallLengthMinutes,
									'Max call length minutes',
									'number'
								)}
								<FormControl variant="standard">
									<InputLabel>Transcription Hints</InputLabel>
									<Input
										value={transcriptionHintInput}
										onChange={updateTranscriptionHint}
										onKeyDown={addTranscriptionHint}
									/>
									<div>
										{transcriptionHints.map((val) => (
											<Chip
												key={val}
												label={val}
												onDelete={() =>
													setTranscriptionHints(
														deleteTranscriptionHint(
															val
														)
													)
												}
											/>
										))}
									</div>
								</FormControl>
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					<div style={{ margin: '0.5rem' }}>
						Admins: {admins.map((a) => a.email_id).join(', ')}
					</div>

					<Typography variant="h6" style={{ margin: '0.5rem' }}>
						Bulk Changes- Create user or change role{' '}
					</Typography>

					<BulkInputHandlerForCustomer
						customerId={customerId}
						rbacEnabled={rbacEnabled}
						classes={classes}
					/>

					{customerId && (
						<Button
							variant="contained"
							className={classes.formField}
							color="info"
							onClick={() => setIsRoleChangeModalOpen(true)}
						>
							Bulk role/permission change
						</Button>
					)}
					<hr style={{ width: '100%' }} />

					<Button
						className={classes.formField}
						variant="contained"
						color={'info'}
						disabled={slackAlertsEnabled}
						onClick={enableSlackAlerts}
					>
						{'Enable Slack Alerts'}
					</Button>


                    <Button
						className={classes.formField}
						variant="contained"
						color={'info'}
						onClick={enableUnifiedAuth}
					>
						{getUnifiedAuthButtonMessage(unifiedAuth)}
					</Button>

					<hr style={{ width: '100%' }} />

					<div style={{ margin: '0.5rem' }}>
						{getRecordingStateButton()}
					</div>
					<div style={{ margin: '0.5rem' }}>
						{getWingmanAccessStateButton()}
					</div>
					{(ssoEnabled || ssoDirectoryEnabled) && (
						<Button
							variant="contained"
							color="secondary"
							className={classes.formField}
							onClick={disableSSO}
							startIcon={<DangerousIcon />}
						>
							Delete Single Sign On
						</Button>
					)}
					<hr style={{ width: '100%' }} />
					<Button
						variant="contained"
						color="primary"
						className={classes.formField}
						onClick={submit}
					>
						Submit
					</Button>
				</FormGroup>
			</Paper>
			<BulkRoleChange
				open={isRoleChangeModalOpen}
				onClose={() => setIsRoleChangeModalOpen(false)}
				customerId={customerId}
				isRbacEnabled={rbacEnabled}
				allRolesAvailableForCustomer={allRolesAvailableForCustomer}
			/>
		</>
	);
}
